import { reverseGeoCode } from "@/services/Mapbox.service";
import { LocalStorage } from "@/services/LocalStorage.service";
import { useEffect, useState } from "react";
import { setCookie, parseCookies } from "nookies";
import { cookieOptions } from "@/lib/constants";



export default function useLocateMe(){
    const [ location, setLocation ] = useState(null)


    async function geoLocate(pos){
        const { latitude, longitude } = pos.coords

        await reverseGeoCode({ latitude, longitude }).then(response => {
            LocalStorage.setStorage({
                key: "GRS_userLocation",
                data: response?.features?.[0],
                type: "local"
            })

            // TODO: normalize the data object with what is being set in ip address service
            setCookie(null, "GRS_userLocation", JSON.stringify(response.features[0]), cookieOptions)

            setLocation(response.features[0])
        })
        
    }


    function error(err){
        console.warn(`ERROR(${err.code}): ${err.message}`);
    }



    function useCurrentLocation(){
        const options = {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0,
        };

        navigator.geolocation.getCurrentPosition(geoLocate, error, options)
    }



    function usePostalCode(code){
        console.log(code)
    }



    useEffect(() => {
        const location = LocalStorage.getStorage("GRS_userLocation")
        const localCookie = parseCookies()

    
        if( location || localCookie?.GRS_userLocation ){
            setLocation(location ?? localCookie?.GRS_userLocation)

        }else{
            useCurrentLocation()
        }

    }, [])


    return{
        data: {
            ...location
        },
        useCurrentLocation,
        usePostalCode
    }
    
}