"use client"

import { useContext } from "react";
import Style from "./User.module.scss";
import clsx from "clsx";
import { GlobalContext } from "@/contexts/GlobalContext";
import MyAccountWidget from "@/components/applications/my-account/MyAccountWidget";


function User({ theme }){
    const [ _, dispatch ] = useContext(GlobalContext)

    function toggleWidget(){
        dispatch({
            type: "setWidget",
            data: {
                isOpen: true,
                component: <MyAccountWidget />,
                heading: "Your Account"
            }
        })
    }


    return(
        <button 
            onClick={toggleWidget}
            type="button" 
            className={clsx(Style.link, Style[theme])}
        >
            <svg className={Style.icon} viewBox="0 0 24 24">
                <path d="M16 14h-8c-2.8 0-5 2.2-5 5v2c0 0.6 0.4 1 1 1s1-0.4 1-1v-2c0-1.7 1.3-3 3-3h8c1.7 0 3 1.3 3 3v2c0 0.6 0.4 1 1 1s1-0.4 1-1v-2c0-2.8-2.2-5-5-5z"></path>
                <path d="M12 12c2.8 0 5-2.2 5-5s-2.2-5-5-5-5 2.2-5 5 2.2 5 5 5zM12 4c1.7 0 3 1.3 3 3s-1.3 3-3 3-3-1.3-3-3 1.3-3 3-3z"></path>
            </svg>

            {/* Sign in */}
        </button>
    )
}


export default User;