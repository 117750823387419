

export const cookieOptions = {
    path: "/",
    secure: true, 
    maxAge: 60*60*24*365,
    domain: process.env.NODE_ENV === "development" ? null : ".gardenroute.store",
}



// Big Commerce Product Requests
export const includeOptions = "include=line_items.physical_items.options"


export const plpProductExcludedFields = [
    "meta_keywords",
    "meta_description",
    "type",
    "option_set_id",
    "description",
    "weight",
    "depth",
    "product_tax_code",
    "width",
    "tax_class_id",
    "option_set_display",
    "inventory_warning_level",
    "inventory_tracking",
    "layout_file",
    "warranty",
    "related_products",
    "open_graph_type",
    "open_graph_title",
    "open_graph_description",
    "open_graph_use_meta_description",
    "open_graph_use_product_name",
    "open_graph_use_image",
    "order_quantity_minimum",
    "order_quantity_maximum",
    "inventory_level",
    "date_last_imported",
    "view_count",
    "date_created",
    "date_modified",
    "categories",
    "base_variant_id",
    "page_title",
    "gift_wrapping_options_list",
    "gift_wrapping_options_type",
    "availability_description",
    "search_keywords",
    "is_condition_shown",
    "preorder_message"
].join(",")