"use client"

import { useEffect, useState } from "react";



export default function useVoice(){
    const [ isListening, setIsListening ] = useState(false);
    const [ speech, setSpeech ] = useState(null)
    const [ result, setResult ] = useState(null)

    function listen(){
        setIsListening(!isListening);

        // if we were listing, then now stop
        if ( isListening ) {
            speech.stop();
            setResult(null)

        }else{
            speech.start()
        }
    }


    useEffect(() => {
        if ( window.webkitSpeechRecognition ) {
            const SpeechRecognition = window.webkitSpeechRecognition;
            const recognition = new SpeechRecognition();
            // const speechRecognitionList = new SpeechGrammarList();

            recognition.continuous = false;
            recognition.lang = "en-ZA";
            recognition.interimResults = false;
            recognition.maxAlternatives = 1;

            recognition.onresult = event => {
                const result = event.results[event.results.length - 1][0];
                setResult(result)
            }

            recognition.onspeechend = () => {
                recognition.stop();
            };

            setSpeech(recognition)

        
        } else {
            setSpeech(null)
        }

    }, [])




    return {
        result,
        isListening,
        isSupported: speech,
        listen
    }

}