"use client"

import { useEffect, useState } from "react";
import RecomendationRow from "../RecomendationRow";
import { usePathname } from "next/navigation";
import ErrorBoundary from "@/services/ErrorBoundary";
import { LocalStorage } from "@/services/LocalStorage.service";


function RecentlyViewed() {
    const [ data, setData ] = useState([])
    const pathName = usePathname()

    useEffect(() => {
        const recentlyViewed = LocalStorage.getStorage("GRS_recentlyViewed")
        setData(recentlyViewed)

    }, [ pathName ])

    return(
        <ErrorBoundary>
            <RecomendationRow 
                data={data}
                title="Your Browsing History" 
            />
        </ErrorBoundary>
    )
}

export default RecentlyViewed;