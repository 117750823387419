

/**
 * Luhn Credit Card Initial Verification
 * @param {Number} num 
 * @returns CC Validation Pass/Fail Boolean
 */

export function luhnCheck(num){
    const arr = (num + '').split('').reverse().map(x => parseInt(x));
    const lastDigit = arr.splice(0, 1)[0];
    let sum = arr.reduce((acc, val, i) => (i % 2 !== 0 ? acc + val : acc + ((val * 2) % 9) || 9), 0);

    sum += lastDigit;

    return sum % 10 === 0;
}



// cart the quantity of products in a cart
export function getCartQnty(cart){
    if( !cart?.line_items ){ return 0; }

    const productQuantities = cart?.line_items?.physical_items.map(item => item?.quantity ?? 0)
    const count = productQuantities.length ? productQuantities.reduce((prev, curr) => prev + curr) : 0;

    return count;
}


