"use client"

import clsx from "clsx";
import Style from "./Search.module.scss";
import { useRouter, useSearchParams } from "next/navigation";
import { useEffect, useState } from "react";
import VoiceSearch from "./VoiceSearch";


function Search({ isLarge }){
    const searchParams = useSearchParams()
    const query = searchParams.get("q")
    const router = useRouter()
    const [ term, setSearchTerm ] = useState(query)


    useEffect(() => {
        setSearchTerm(query)

    }, [ searchParams ])


    function onSearch(e){
        if( e.key === "Enter" || e.type === 'submit' ){
            router.push(`/search?q=${term}`)

        }else{
            setSearchTerm(e.target.value)
        }

    }

    return(
        <search className={Style.block} onKeyDown={onSearch}>
            <button type="button" className={Style.button} onClick={onSearch}>
                <svg id="icon-search" viewBox="0 0 32 32" className={Style.icon}>
                    <path d="M19.427 20.427c-1.39 0.99-3.090 1.573-4.927 1.573-4.694 0-8.5-3.806-8.5-8.5s3.806-8.5 8.5-8.5c4.694 0 8.5 3.806 8.5 8.5 0 2.347-0.951 4.472-2.49 6.010l5.997 5.997c0.275 0.275 0.268 0.716-0.008 0.992-0.278 0.278-0.72 0.28-0.992 0.008l-6.081-6.081zM14.5 21c4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5c-4.142 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5v0z"></path>
                </svg>
            </button>

            <input 
                type="search" 
                placeholder="What are you looking for today?" 
                className={clsx(Style.input, isLarge && Style.large)}
                value={term ?? ""}
                onChange={onSearch}
            />
            
            <VoiceSearch 
                onResult={(text) => router.push(`/search?q=${text}`)} 
            />
        </search>
    )
}

export default Search;