/** 
 * MapBox Service 
 * ======================
 **/



/**
 * Reverse Geocoding
 * https://docs.mapbox.com/api/search/geocoding/#reverse-geocoding
 * @param {number} longitude 
 * @param {number} latitude
 * @param {string} endpoint
 * @returns geo location data of the requestor
 */

export async function reverseGeoCode({ longitude, latitude, endpoint = 'mapbox.places' }){
    return await fetch(`https://api.mapbox.com/geocoding/v5/${endpoint}/${longitude},${latitude}.json?access_token=${process.env.NEXT_PUBLIC_mapboxToken}&types=postcode`)
        .then(res => res.json())
        .then(data => data)
        .catch(err => console.log(err))
}


