"use client"

import Input from "@/components/forms/Input";
import Style from "./LocalizeWidget.module.scss"
import { IconLocation } from "@/icons/IconLocation";
import useLocateMe from "@/global_hooks/useLocateMe";
import { useState } from "react";
import Button from "@/components/forms/Button";
import WidgetBody from "@/components/display/WidgetBody";


function LocalizeWidget(){
    const [ postalCode, setPostalCode ] = useState("")
    const location = useLocateMe()

    function updatePostal(){
        location.usePostalCode(postalCode)
    }

    return(
        <WidgetBody 
            className={Style.content}
            actions={
                <Button color="ocean" type="button" onClick={updatePostal}>
                    Update
                </Button>
            }
        >
            <p>Inventory and shipping/delivery options may change based on your location</p>

            <p>
                <Input 
                    className={Style.input}
                    label="Postal Code"
                    name="postalCode"
                    placeholder="Enter postal code"
                    onChange={(e) => setPostalCode(e.target.value)}
                    autoFocus
                />
            </p>

            
            <button 
                className={Style.myLocationBtn}
                type="button"
                onClick={location.useCurrentLocation}
            >
                <IconLocation width="30" height="30" />
                Use my current location
            </button>
        </WidgetBody>
    )
}





export default LocalizeWidget;