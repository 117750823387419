import useVoice from "@/global_hooks/useVoice";
import Style from "./VoiceSearch.module.scss";
import { IconMic } from "@/icons/IconMic";
import clsx from "clsx";
import { useEffect, useState } from "react";


function VoiceSearch({ 
    onResult = () => null 
}){
    const speech = useVoice()
    const [ done, setDone ] = useState(false)


    useEffect(() => {
        if( speech?.result?.confidence > 0.8 && !done ){
            onResult(speech.result.transcript)
            setDone(true)

        }else if( speech?.result?.confidence < 0.8 ){
            alert("Sorry, I didn't quite catch that. Please try again.")
        }

    }, [ speech ])



    function startListening(){
        speech.listen()
        setDone(false)
    }


    return(
        <>
            {speech.isSupported && (
                <button 
                    type="button" 
                    className={clsx(Style.block, speech.isListening && Style.listening)} 
                    disabled={!speech.isSupported}
                    onClick={startListening}
                >
                    <IconMic className={Style.icon} />
                </button>
            )}
        </>
    )
}

export default VoiceSearch;
