import Link from "next/link";
import Style from "./MyAccountWidget.module.scss";
import WidgetBody from "@/components/display/WidgetBody";
import { signIn, signOut } from "next-auth/react";


function MyAccountWidget(){
    return(
        <WidgetBody className={Style.content}>
            <menu className={Style.menu}>
                <li className={Style.item}>
                    <button type="button" onClick={() => signIn("email", {email: "grantmx@gmail.com" })} className={Style.link}>
                        Sign In / Create Account
                    </button>
                </li>

                <li className={Style.item}>
                    <Link href="/account" className={Style.link}>
                        Account
                    </Link>
                </li>
                
                <li className={Style.item}>
                    <Link href="/account/orders" className={Style.link}>
                        My Orders
                    </Link>
                </li>

                <li className={Style.item}>
                    <Link href="/account/saved-items" className={Style.link}>
                        My Saved Items
                    </Link>
                </li>

                <li className={Style.item}>
                    <Link href="/account/gift-cards" className={Style.link}>
                        Gift Cards
                    </Link>
                </li>

                <li className={Style.item}>
                    <Link href="/account/history" className={Style.link}>
                        Browsing History
                    </Link>
                </li>

                <li className={Style.item}>
                    <button type="button" onClick={() => signOut()} className={Style.link}>
                        Sign Out
                    </button>
                </li>
            </menu>
        </WidgetBody>
    )
}

export default MyAccountWidget;